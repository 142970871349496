import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import fullScreen from 'lightgallery/plugins/fullscreen'
import Cookie from 'js.cookie';
export default {
  init() {
    // JavaScript to be fired on all pages

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // toggles hamburger and nav open and closed states
    var removeClass = true;
    $('.hamburger').click(function () {
      $('.hamburger').toggleClass('is-active');
      $('#sideNav').toggleClass('sideNav-open');
      $('.sideNavBody').toggleClass('sideNavBody-push');
      removeClass = false;
    });

    $('.sideNav').click(function() {
      removeClass = false;
    });

    document.addEventListener('touchstart', function(e) {
      if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
        $('.hamburger').removeClass('is-active');
        $('#sideNav').removeClass('sideNav-open');
        $('.sideNavBody').removeClass('sideNavBody-push');
      }
      removeClass = true;
    }, false);

    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });
    
    $('.scrollup').click(function () {
      $('html, body').animate({
        scrollTop: 0,
      }, 600);
      return false;
    });

    if (!Cookie.get('alert') && $('#modal').length) {
      $('#modal').show();
    }
    
    $('.modal .btn-close').click(function() {
      $('#modal').hide();
      Cookie.set('alert', true, {
        expires: 1,
      });
    });

    $('img').parent('a').addClass('no-external-link');
    $('i').parent('a').addClass('no-external-link');

    //dropdown on click
    $('.banner li.menu-item-has-children > a').click(function(e) {
      e.preventDefault();
      $(this).parent('.banner li.menu-item-has-children').toggleClass('open');
      $(this).parent('.banner li.menu-item-has-children').siblings('.open').removeClass('open');
    });
  
    //close dropdown when clicking anywhere else
    $(document).on('click touch', function(e) {
      if( !$(e.target).hasClass('.banner li.menu-item-has-children') && !$(e.target).parents('.banner li.menu-item-has-children').length ) {
        $('.banner li.menu-item-has-children.open').removeClass('open');
      }
    });
  
    //sidenav dropdown on click
    $('.sideNav li.menu-item-has-children > a').click(function(e){
      e.preventDefault();
      $(this).next('.sideNav .sub-menu').slideToggle();
      $(this).parent('.sideNav li.menu-item-has-children').toggleClass('open');
    });
 
    if ($('.slick-testimonials').length) {
      $('.slick-testimonials').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        appendArrows: '.slick-testimonials',
        appendDots: '.slick-testimonials',
        adaptiveHeight: true,
        arrows: true,
        dots: false,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots: true,
            },
          },
        ],
      });
    }

    lightGallery(document.getElementById('project-gallery'), {
      licenseKey: 'F28B4CA0-283749F1-BBEB75B9-915E2B0B',
      selector: '.gallery-image',
      plugins: [lgZoom, lgThumbnail, fullScreen],
      speed: 500,
      fullScreen: true,
      mobileSettings: {
        controls: true,
        showCloseIcon: true,
        download: false,
      },
    });

    /* serves hero image variant based on breakpoint
    * needs cloudflare cdn on div with hero class
    */
    $(window).resize(function() {
      if ($(window).width() < 1600) {
        $('.hero.cloudflare').attr('style', function(_, value) {
          return value.replace('/1920px', '/1600px');
        });
      } else {
        $('.hero.cloudflare').attr('style', function(_, value) {
          return value.replace('/1600px', '/1920px');
        });
      }
      if ($(window).width() < 1440) {
        $('.hero.cloudflare').attr('style', function(_, value) {
          return value.replace('/1600px', '/1440px');
        });
      } else {
        $('.hero.cloudflare').attr('style', function(_, value) {
          return value.replace('/1440px', '/1600px');
        });
      }
      if ($(window).width() < 1200) {
        $('.hero.cloudflare').attr('style', function(_, value) {
          return value.replace('/1440px', '/1200px');
        });
      } else {
        $('.hero.cloudflare').attr('style', function(_, value) {
          return value.replace('/1200px', '/1440px');
        });
      }
      if ($(window).width() < 992) {
        $('.hero.cloudflare').attr('style', function(_, value) {
          return value.replace('/1200px', '/992px');
        });
      } else {
        $('.hero.cloudflare').attr('style', function(_, value) {
          return value.replace('/992px', '/1200px');
        });
      }
      if ($(window).width() < 768) {
        $('.hero.cloudflare').attr('style', function(_, value) {
          return value.replace('/992px', '/768px');
        });
      } else {
        $('.hero.cloudflare').attr('style', function(_, value) {
          return value.replace('/768px', '/992px');
        });
      }
      
      if ($(window).width() < 576) {
        $('.hero.cloudflare').attr('style', function(_, value) {
          return value.replace('/768px', '/576px');
        });
      } else {
        $('.hero.cloudflare').attr('style', function(_, value) {
          return value.replace('/576px', '/768px');
        });
      }
    }).trigger('resize');
  },
};
